import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import MasonryList from '../components/masonryList';
import search from '../image/search.png';
import Linkify from 'react-linkify';
import useClickHandler from '../hooks/useClickHandler';
import Loader from 'react-loader-spinner';
import { doDelete, doGet } from '../Rest/Fetcher';
import { toast } from 'react-hot-toast';
import { AdminMode } from '../types/dataTypes';
import { fetchEventsByYear } from '../Rest/fecthEvents';
import { EventActionType } from '../reducer/eventsReducer';
import { AuthContext, EventContext } from '../appContext';
import { MagnumEvent, NoEvent } from '../types/magnumEvent';

export default function EventsList() {
  const [showDate, setShowDate] = useState(false);
  const [deleteMode, setDeleteMode] = useState<AdminMode>({
    isActive: false,
    event: NoEvent,
  });
  const [editMode, setEditMode] = useState<AdminMode>({
    isActive: false,
    event: NoEvent,
  });
  const [selectedEventIsLoading, setSelectedEventIsLoading] = useState(false);
  const [eventsAreLoading, setEventsAreLoading] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const filterDateRef = useRef<HTMLDivElement>(null);

  const { eventState, dispatchEvent } = useContext(EventContext);
  const { authState } = useContext(AuthContext);

  //On récupère l'année scolaire : 2020 pour 2020-2021,...
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = month < 8 ? today.getFullYear() - 1 : today.getFullYear();

  const [dateFilter, setDateFilter] = useState(year);

  useEffect(() => {
    (async () => {
      setEventsAreLoading(true);
      try {
        const events = await fetchEventsByYear(dateFilter);
        dispatchEvent({ type: EventActionType.EVENTS_FETCHED, params: events });
        setEventsAreLoading(false);
      } catch (e) {
        setEventsAreLoading(false);
        toast.error('Error retrieving events');
      }
    })();
  }, [dateFilter, dispatchEvent]);

  //function to stop the body from scrolling when the modal is opened
  useEffect(() => {
    if (eventState.selectEvent) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [eventState]);

  useClickHandler(() => setShowDate(false), [filterDateRef]);
  useClickHandler(
    () =>
      dispatchEvent({
        type: EventActionType.EVENT_SELECTED,
        params: undefined,
      }),
    [modalRef],
  );

  const handleChange = (filter: React.ChangeEvent<any>) => {
    dispatchEvent({
      type: EventActionType.FILTER_UPDATED,
      params: filter.target?.value.toLowerCase(),
    });
  };

  const filter = () => {
    return (event: MagnumEvent) =>
      event.title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(eventState.filter.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  };

  const handleSelectEvent = async (event: MagnumEvent) => {
    if (deleteMode.isActive || editMode.isActive) {
      return;
    }
    setSelectedEventIsLoading(true);
    await doGet('/api/events/' + event.id)
      .then((res: MagnumEvent) => {
        dispatchEvent({ type: EventActionType.EVENT_SELECTED, params: res });
        setSelectedEventIsLoading(false);
      })
      .catch(() => {
        setSelectedEventIsLoading(false);
        toast.error('Error retrieving event');
      });
  };

  const handleDeleteEvent = (id: string) => {
    doDelete(authState.auth.token, '/api/events/' + id)
      .then(() => {
        toast.success('Event deleted succesfully');
        setDeleteMode({ isActive: false, event: NoEvent });
        dispatchEvent({ type: EventActionType.EVENT_DELETED, params: id });
      })
      .catch((error) => toast.error(error.message));
  };

  function dateToString(date: string) {
    return date?.slice(0, 10).split('-').reverse().join('/');
  }

  return (
    <EventList.Wrapper>
      {authState.auth.admin === 'SUPERADMIN' || authState.auth.admin === 'ADMIN' ? (
        <Admin.Wrapper>
          <Admin.CreateEvent href={'/create-event'}> </Admin.CreateEvent>
          <Admin.EditEvent
            onClick={() => {
              setDeleteMode({ isActive: false, event: NoEvent });
              setEditMode({ isActive: !editMode.isActive, event: NoEvent });
            }}
          >
            {' '}
          </Admin.EditEvent>
          <Admin.DeleteEvent
            onClick={() => {
              setDeleteMode({ isActive: !deleteMode.isActive, event: NoEvent });
              setEditMode({ isActive: false, event: NoEvent });
            }}
          >
            {' '}
          </Admin.DeleteEvent>
        </Admin.Wrapper>
      ) : (
        <></>
      )}
      <Filter.Wrapper>
        <Filter.MaxInput>
          <Filter.Input
            type="text"
            placeholder="Search"
            onChange={handleChange}
            value={eventState.filter}
          />
          <Filter.SearchLogo src={search} />
        </Filter.MaxInput>
        <Filter.ButtonWrapper ref={filterDateRef}>
          <Filter.Button onClick={() => setShowDate(!showDate)} selected={showDate}>
            {`${dateFilter}-${dateFilter + 1}`}
          </Filter.Button>
          {showDate ? (
            <Filter.DropDown>
              {[...Array(year - 2016 + 1)].map((_, index) => {
                return (
                  <Filter.DropDownContent
                    key={index}
                    onClick={() => {
                      setShowDate(false);
                      setDateFilter(year - index);
                    }}
                  >
                    {`${year - index}-${year + 1 - index}`}
                  </Filter.DropDownContent>
                );
              })}
            </Filter.DropDown>
          ) : null}
        </Filter.ButtonWrapper>
      </Filter.Wrapper>
      {eventsAreLoading ? (
        <div style={{ margin: '100px 0' }}>
          <Loader type="Oval" color="#9C8F7F" />
        </div>
      ) : (
        <MasonryList>
          {eventState.allEvents.filter(filter()).map((event, i) => {
            return (
              <EventList.Box
                key={i}
                onClick={() => handleSelectEvent(event)}
                deleteMode={deleteMode.isActive}
                editMode={editMode.isActive}
              >
                {deleteMode.isActive ? (
                  <>
                    <EventList.WhiteTriangle />
                    <EventList.DeleteMode
                      onClick={() => setDeleteMode({ isActive: false, event: event })}
                    />
                  </>
                ) : (
                  <></>
                )}
                {editMode.isActive ? (
                  <>
                    <EventList.WhiteTriangle />
                    <EventList.EditMode href={'/create-event/' + event.id} />
                  </>
                ) : (
                  <></>
                )}
                {event.image ? <EventList.Image src={'/api/images/' + event.image} /> : <></>}
                <EventList.Type>{event.type}</EventList.Type>
                <EventList.TitleWrapper>
                  <EventList.Title>{event.title + ' - '} </EventList.Title>
                  <EventList.Date>{dateToString(event.date)}</EventList.Date>
                </EventList.TitleWrapper>
              </EventList.Box>
            );
          })}
        </MasonryList>
      )}

      {!eventState.selectEvent ? (
        <div ref={modalRef} />
      ) : (
        ReactDOM.createPortal(
          <Modal.Overlay>
            {selectedEventIsLoading ? (
              <div ref={modalRef} style={{ marginTop: '40vh' }}>
                <Loader type="Oval" color="#9C8F7F" />
              </div>
            ) : (
              <Modal.Wrapper ref={modalRef}>
                <Modal.CloseButton
                  onClick={() =>
                    dispatchEvent({
                      type: EventActionType.EVENT_SELECTED,
                      params: undefined,
                    })
                  }
                >
                  <span>&times;</span>
                </Modal.CloseButton>
                <Modal.Header>
                  <EventList.Type>{eventState.selectEvent.type}</EventList.Type>
                  <EventList.TitleWrapper>
                    <EventList.Title>{eventState.selectEvent.title + ' - '} </EventList.Title>
                    <EventList.Date>{dateToString(eventState.selectEvent.date)}</EventList.Date>
                  </EventList.TitleWrapper>
                  {eventState.selectEvent.image ? (
                    <Modal.Image src={'/api/images/' + eventState.selectEvent.image} />
                  ) : (
                    <></>
                  )}
                </Modal.Header>
                <Linkify>
                  <Modal.Body>{eventState.selectEvent.body}</Modal.Body>
                </Linkify>
                <Modal.Footer>
                  {eventState.selectEvent.pics ? (
                    <Modal.Pics
                      href={eventState.selectEvent.pics}
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    />
                  ) : (
                    <></>
                  )}
                </Modal.Footer>
              </Modal.Wrapper>
            )}
          </Modal.Overlay>,
          document.body,
        )
      )}
      {deleteMode.event === NoEvent ? (
        <></>
      ) : (
        <Modal.Overlay>
          <Modal.Wrapper>
            <Admin.DeleteModal>
              <Admin.DeleteModalText>
                {"Être-vous sur de vouloir supprimer l'évenement '" +
                  deleteMode.event.title +
                  "' ?"}
              </Admin.DeleteModalText>
              <Admin.DeleteModalCancel
                onClick={() => setDeleteMode({ isActive: false, event: NoEvent })}
              >
                Conserver
              </Admin.DeleteModalCancel>
              <Admin.DeleteModalAccept onClick={() => handleDeleteEvent(deleteMode.event.id)}>
                Supprimer
              </Admin.DeleteModalAccept>
            </Admin.DeleteModal>
          </Modal.Wrapper>
        </Modal.Overlay>
      )}
    </EventList.Wrapper>
  );
}

//Style

type EventListType = {
  editMode: boolean;
  deleteMode: boolean;
};

const EventList = {
  Wrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background: none;
    margin-top: 75px;
  `,
  Col: styled.div`
    display: grid;
  `,

  Image: styled.img`
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    font-size: 10px;
    line-height: 10px;
    cursor: pointer;
    display: flex;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,

  Events: styled.div`
    position: relative;
    align-items: center;
    width: 99%;
    height: 100%;
  `,

  Box: styled.div<EventListType>`
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    will-change: transform, width, height, opacity;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: center;
    background: white;
    height: min-content;
    cursor: pointer;
    z-index: 1;

    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: ${(props) => (props.editMode || props.deleteMode ? 'scale(1)' : 'scale(1.1)')};
      box-shadow: ${(props) =>
        props.editMode || props.deleteMode
          ? '0 1px 2px rgba(0, 0, 0, 0.15)'
          : '0 5px 15px rgba(0, 0, 0, 0.3)'};
    }

    @media only screen and (max-width: 50em) {
      &:hover {
        transform: none;
        box-shadow: none;
      }
    }
  `,

  Title: styled.span`
    font-family: 'Playfair Display', serif;
    text-align: center;
    max-width: 70%;
    font-weight: 600;
    font-size: 16px;
  `,

  Date: styled.span`
    font-family: 'Playfair Display', serif;
    text-align: center;
    max-width: 70%;
    font-weight: 300;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  `,

  Type: styled.p`
    text-transform: uppercase;
    font-size: 14px;
    max-width: 90%;
    font-weight: 400;
    margin: 20px auto 0 auto;
    padding: 0 20px 0.75rem 20px;
    text-align: center;
    letter-spacing: 0.25rem;
    color: #999999;
    border-bottom: 1px solid #999999;
  `,

  TitleWrapper: styled.p`
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 90%;
  `,

  WhiteTriangle: styled.div`
    width: 0;
    height: 0;
    border-top: 40px solid white;
    border-bottom: 40px solid transparent;
    border-left: 40px solid transparent;
    border-right: 40px solid white;
    right: 0;
    position: fixed;
    z-index: 2;
  `,

  DeleteMode: styled.div`
    background: url('${process.env.PUBLIC_URL}/events/delete.png');
    position: fixed;
    z-index: 3;
    right: 10px;
    top: 10px;
    font-size: 2rem;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    background-size: 100% auto;
  `,

  EditMode: styled.a`
    background: url('${process.env.PUBLIC_URL}/events/edit.png');
    position: fixed;
    z-index: 2;
    right: 10px;
    top: 10px;
    font-size: 2rem;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    background-size: 100% auto;
  `,
};

type FilterProp = {
  selected: boolean;
};

const Filter = {
  Button: styled.button<FilterProp>`
    height: 2.5em;
    background-color: ${(props) => (props.selected ? 'rgb(93, 73, 59)' : '#9C8F7F')};
    z-index: ${(props) => (props.selected ? 2 : 0)};
    width: 160px;
    border-radius: 0.4em;
    border: none;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 1.2em;
    text-align: center;
    padding: 0.5em 1em 0.5em 1em;
    position: relative;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: rgb(93, 73, 59);
      color: white;
    }
  `,

  MaxInput: styled.div`
    width: 400px;
    display: flex;
    justify-content: center;
    margin-right: 20px;

    @media only screen and (max-width: 50em) {
      width: 100%;
      margin: 1em 0 1em 0;
    }
  `,

  Input: styled.input`
    border: 2px solid #9c8f7f;
    outline: none;
    display: block;
    width: 40%;
    height: 2.5em;
    background-color: white;
    border-radius: 0.4em;
    text-transform: uppercase;
    font-size: 1.2em;
    text-align: center;
    padding: 0.5em 3em 0.5em 1em;
    transition: all 0.4s ease;

    &:focus {
      outline: none;
      border: 2px solid #9c8f7f;
      width: 100%;
    }

    @media only screen and (max-width: 50em) {
      margin: 0;
    }
  `,

  Wrapper: styled.div`
    display: flex;
    width: 100%;
    background: none;
    justify-content: center;
    @media only screen and (max-width: 50em) {
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 90%;
    }
  `,

  SearchLogo: styled.img`
    width: 1.5em;
    height: 1.5em;
    margin-top: 0.8em;
    margin-left: -2.5em;
    float: right;
  `,

  DropDown: styled.div`
    background: rgb(93, 73, 59);
    padding-top: 0.6em;
    overflow: hidden;
    position: absolute;
    top: 2.4em;
    z-index: 3;
    display: flex;
    border-radius: 0.4em;
    flex-direction: column;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    @media only screen and (max-width: 50em) {
      top: 2.2em;
    }
  `,

  DropDownContent: styled.button`
    cursor: pointer;
    color: white;
    height: 2em;
    background: #9c8f7f;
    border: none;
    text-transform: uppercase;
    font-size: 1em;
    text-align: center;
    padding: 0.5em 1em 0.5em 1em;

    &:hover {
      background: rgb(93, 73, 59);
    }
  `,

  ButtonWrapper: styled.div`
    position: relative;
    margin-right: 20px;

    @media only screen and (max-width: 50em) {
      margin: 0 0 0 15px;
    }
  `,
};
const Modal = {
  Overlay: styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);
  `,

  Body: styled.div`
    white-space: pre-wrap;
    text-align: justify;
    line-height: 1.75;
    font-weight: 400;
    font-size: 15px;
    padding: 2rem 4rem;
    background: white;
    width: 100%;
  `,

  Wrapper: styled.div`
    z-index: 1050;
    width: 800px;

    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 2rem);
    position: relative;
    outline: 0;
    display: flex;
    margin-top: 2rem;
    max-width: 90%;
    flex-direction: column;
    align-items: center;

    ::-webkit-scrollbar {
      width: 13px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    ::-webkit-scrollbar-thumb {
      border-left: 7px solid rgba(0, 0, 0, 0.5);
      background: white;
    }

    @media only screen and (max-width: 50em) {
      ::-webkit-scrollbar {
        width: inherit;
      }

      margin: 0;
      max-height: 100vh;
      max-width: 100%;
      width: 100%;
      background: white;
    }
  `,

  CloseButton: styled.div`
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    border: none;
    background: transparent;
    top: 3.5rem;
    right: calc((100vw - 800px) / 2 + 1rem);
    position: fixed;
    width: 3rem;
    height: 3rem;

    @media only screen and (max-width: 50em) {
      position: sticky;
      margin-left: 85vw;
      top: 1em;
    }
  `,

  Pics: styled.a`
    display: inline-block;
    width: 86px;
    height: 40px;
    background: url(${process.env.PUBLIC_URL + '/events/pics.png'});
    border: none;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    background-size: cover;
  `,

  Footer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;
    background: white;
  `,

  Header: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
  `,

  Image: styled.img`
    max-height: 600px;
    width: 100%;
    height: auto;
    object-fit: contain;
  `,
};

const Admin = {
  Wrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;
  `,

  CreateEvent: styled.a`
    background: url('${process.env.PUBLIC_URL}/events/plus.png');
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 100% auto;
    margin-right: 40px;
  `,

  DeleteEvent: styled.button`
    background: url('${process.env.PUBLIC_URL}/events/delete.png');
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 100% auto;
    margin-right: 40px;
    border: none;
  `,

  EditEvent: styled.button`
    background: url('${process.env.PUBLIC_URL}/events/edit.png');
    display: inline-block;
    width: 37px;
    height: 37px;
    background-size: 100% auto;
    margin-right: 40px;
    border: none;
  `,

  DeleteModal: styled.div`
    z-index: 1050;
    width: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    outline: 0;
    display: flex;
    max-width: 90%;
    align-items: center;
    background: white;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 40vh;
    padding: 20px 0;
  `,

  DeleteModalText: styled.div`
    white-space: pre-wrap;
    text-align: justify;
    line-height: 1.75;
    font-weight: 400;
    font-size: 15px;
    color: #a6a6a6;
    padding: 2rem 4rem;
    background: white;
    width: 100%;
  `,

  DeleteModalCancel: styled.div`
    background: rgb(34, 177, 76);
    width: 150px;
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.1em;
    text-align: center;
    border-radius: 0.4em;
  `,

  DeleteModalAccept: styled.div`
    background: red;
    width: 150px;
    padding: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.1em;
    text-align: center;
    border-radius: 0.4em;
  `,
};
