import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import { doGet } from '../Rest/Fetcher';
import styled from 'styled-components';
import { MagnumEvent } from '../types/magnumEvent';

export default function Home() {
  const [events, setEvents] = useState<MagnumEvent[]>([]);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    doGet('/api/events/home')
      .then((response: MagnumEvent[]) => {
        setEvents(response);
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <>
      <Carousel
        style={{
          width: '100%',
          height: windowHeight,
          zIndex: 1,
          overflow: 'hidden',
        }}
      >
        {events.map((event, key) => {
          return (
            <Carousel.Item key={key}>
              <CarouselStyle.Image
                image={'/api/images/' + event.image}
                style={{ height: windowHeight + 'px' }}
              >
                <CarouselStyle.Caption>
                  <CarouselStyle.Type>{event.type}</CarouselStyle.Type>
                  <CarouselStyle.Title>{event.title}</CarouselStyle.Title>
                </CarouselStyle.Caption>
              </CarouselStyle.Image>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <StyledText.Title>Bienvenue sur le site de Magnum !</StyledText.Title>
      <StyledText.Wrapper>
        <StyledText.Text>
          Magnum est l'association d'oenologie de l'école CentraleSupélec basée à Gif-sur-Yvette.
          Nous organisons des dégustations ouvertes aux étudiants de l'école pour lesquelles nous
          invitons des domaines prestigieux pour qu'ils viennent nous présenter leurs vins. Nous
          avons par exemple eu la chance d'accueillir le chateau Cheval Blanc, Chateau Yquem, ou
          encore la maison de champagne Krug, pour des soirées dégustation de 50 à 70 personnes !
          <br />
          Nos membres investis organisent également des week-end de formation pour les membres de
          l'association, durant lesquels nous allons visiter des domaines et en apprendre plus sur
          l'art de faire du vin, de la vigne à la bouteille. Nous sommes notamment allés dans le
          bordelais, où nous avons visité les domaine Haut-Marbuzet et Chateau Pressac, mais aussi
          en Bourgogne où nous avons découvert la maison Louis Jadot, sans oublier les
          Côtes-du-Rhône, où nous avons pu appréhender divers Saint-Joseph et clairettes de Die.
        </StyledText.Text>
        <StyledText.Image src={'magnum.png'} alt={'logo magnum'} />
      </StyledText.Wrapper>
      <Presentation.Container>
        <Presentation.PresentationVideo
          className="video"
          src={'/home/video_asso.mp4'}
          controls
          poster={'/home/mignature_video.png'}
        />
      </Presentation.Container>
    </>
  );
}

const Presentation = {
  PresentationVideo: styled.video`
    width: 80%;
    outline: none;
    margin: 40px 0;
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
};

type ImageProps = {
  image: string;
};

const CarouselStyle = {
  Title: styled.h4`
    font-family: 'Playfair Display', serif;
    text-align: center;
    max-width: 70%;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
  `,

  Caption: styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 50%;

    @media only screen and (max-width: 40em) {
      width: 100%;
    }
  `,

  Image: styled.div<ImageProps>`
    background-position: center;
    background-size: cover;
    background-image: url(${(props) => process.env.PUBLIC_URL + props.image});
    width: 100vw;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  `,

  Type: styled.p`
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    max-width: 80%;
    font-weight: 300;
    margin: 20px auto 0 auto;
    padding: 0 20px 0.75rem 20px;
    letter-spacing: 0.25rem;
    border-bottom: 1px solid #999999;
    color: white;
  `,
};

const StyledText = {
  Wrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
  `,

  Title: styled.h1`
    font-family: 'Playfair Display', serif;
    text-align: center;
    max-width: 100%;
    font-size: 60px;
    margin-top: 40px;
    margin-bottom: 20px;

    @media only screen and (max-width: 50em) {
      font-size: 40px;
    }
  `,

  Text: styled.div`
    display: flex;
    margin: 0 50px;
    text-align: justify;
    line-height: 2;
    width: 50%;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    padding: 1rem 3rem;

    @media only screen and (max-width: 50em) {
      width: 100%;
      margin: 0;
      font-size: 15px;
    }
  `,

  Type: styled.p`
    text-transform: uppercase;
    font-size: 14px;
    max-width: 300px;
    font-weight: 300;
    margin: 10px auto 0 auto;
    padding: 0 20px 0.75rem 20px;
    letter-spacing: 0.25rem;
    color: #999999;
    border-bottom: 1px solid #999999;
  `,

  Image: styled.img`
    width: 30%;
    object-fit: contain;
    @media only screen and (max-width: 50em) {
      width: 100vh;
      height: 0;
    }
  `,
};
