import * as React from 'react';
import { EventAction, EventsState, initialEventState } from './reducer/eventsReducer';
import { AuthAction, AuthState, initialAuthState } from './reducer/authReducer';

export type EventContextType = {
  eventState: EventsState;
  dispatchEvent: (action: EventAction) => void;
};

export const EventContext = React.createContext<EventContextType>({
  eventState: initialEventState,
  dispatchEvent: () => {},
});

export type AuthContextType = {
  authState: AuthState;
  dispatchAuth: (action: AuthAction) => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  authState: initialAuthState,
  dispatchAuth: () => {},
});
