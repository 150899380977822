import React, { ReactElement, useRef } from 'react';
import useMedia from '../hooks/useMedia';
import styled from 'styled-components';

const fillCols = (children: ReactElement[], cols: ReactElement[][]) => {
  children.forEach((child, i) => cols[i % cols.length].push(child));
};

type Masonry = {
  children: ReactElement[];
};

export default function MasonryList({ children }: Masonry) {
  const ref = useRef<HTMLDivElement>(null);
  const numCols = useMedia(
    ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 800px)', '(min-width: 600px)'],
    [5, 4, 3, 2],
    1,
  );
  const cols: ReactElement[][] = [...Array(numCols)].map(() => []);
  fillCols(children, cols);

  return (
    <MasonryDiv ref={ref} columns={numCols}>
      {[...Array(numCols)].map((_, index) => (
        <Col key={index}>{cols[index]}</Col>
      ))}
    </MasonryDiv>
  );
}

type MasonryDivProps = {
  columns: number;
};

const MasonryDiv = styled.div<MasonryDivProps>`
  width: 100%;
  height: 100%;
  padding-left: 1vw;
  display: grid;
  justify-items: center;
  grid-auto-flow: column;
  grid-template-columns: repeat(
    ${(props) => props.columns},
    ${(props) => (1 / props.columns) * 100 - 0.1 + '%'}
  );
`;
const Col = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 1;
  padding: 15px;
`;
