import produce, { Draft } from 'immer';
import { MagnumEvent } from '../types/magnumEvent';

export interface EventsState {
  allEvents: MagnumEvent[];
  selectEvent?: MagnumEvent;
  filter: string;
}

export const initialEventState: EventsState = {
  allEvents: Array<MagnumEvent>(),
  filter: '',
};

export enum EventActionType {
  EVENTS_FETCHED,
  EVENT_SELECTED,
  EVENT_CREATED,
  EVENT_UPDATED,
  EVENT_DELETED,
  FILTER_UPDATED,
}

export type EventsFetchedAction = {
  type: EventActionType.EVENTS_FETCHED;
  params: MagnumEvent[];
};
export type EventSelectedAction = {
  type: EventActionType.EVENT_SELECTED;
  params: MagnumEvent | undefined;
};
export type EventCreatedAction = {
  type: EventActionType.EVENT_CREATED;
  params: MagnumEvent;
};
export type EventUpdatedAction = {
  type: EventActionType.EVENT_UPDATED;
  params: MagnumEvent;
};
export type EventDeletedAction = {
  type: EventActionType.EVENT_DELETED;
  params: string;
};
export type FilterUpdatedAction = {
  type: EventActionType.FILTER_UPDATED;
  params: string;
};

export type EventAction =
  | EventsFetchedAction
  | EventSelectedAction
  | EventDeletedAction
  | EventUpdatedAction
  | EventCreatedAction
  | FilterUpdatedAction;

export const eventsReducer = (state: EventsState, action: EventAction): EventsState =>
  produce(state, (draft: Draft<EventsState>) => {
    switch (action.type) {
      case EventActionType.EVENTS_FETCHED:
        draft.allEvents = action.params;
        break;
      case EventActionType.EVENT_SELECTED:
        draft.selectEvent = action.params;
        break;
      case EventActionType.EVENT_DELETED:
        draft.allEvents = state.allEvents.filter((event) => event.id !== action.params);
        break;
      case EventActionType.EVENT_UPDATED:
        break;
      case EventActionType.EVENT_CREATED:
        break;
      case EventActionType.FILTER_UPDATED:
        draft.filter = action.params;
        break;
    }
  });
