import React, { useCallback, useEffect, useReducer } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Home from './pages/home';
import Navigation from './components/navigation';
import Footer from './pages/footer';
import EventsList from './pages/eventsList';
import Entreprise from './pages/entreprise';
import Shotgun from './pages/shotgun';
import styled, { createGlobalStyle } from 'styled-components';
import Logout from './components/logout';
import CreateEvent from './pages/createEvent';
import { Toaster } from 'react-hot-toast';
import { eventsReducer, initialEventState } from './reducer/eventsReducer';
import { AuthContext, EventContext } from './appContext';
import SuperAdmin from './pages/superAdmin';
import { AuthActionType, authReducer, initialAuthState } from './reducer/authReducer';
import { doGet } from './Rest/Fetcher';
import { InMemoryAuth } from './types/inMemoryAuth';

function App() {
  const allEvents = useReducer(eventsReducer, initialEventState);
  const eventContext = {
    eventState: allEvents[0],
    dispatchEvent: allEvents[1],
  };

  const inMemoryAuth = useReducer(authReducer, initialAuthState);
  const authContext = {
    authState: inMemoryAuth[0],
    dispatchAuth: inMemoryAuth[1],
  };
  const dispatchAuth = inMemoryAuth[1];

  const refreshToken = useCallback(() => {
    if (localStorage.getItem('isLogged') === 'true') {
      doGet('/api/auth/refresh-token')
        .then((res: InMemoryAuth) => {
          dispatchAuth({
            type: AuthActionType.AUTH_UPDATED,
            params: res,
          });
          setTimeout(() => {
            refreshToken();
          }, 15 * 60 * 1000 - 500);
        })
        .catch((err) => console.error(err.message));
    }
  }, [dispatchAuth]);

  useEffect(() => refreshToken(), [refreshToken]);

  return (
    <AuthContext.Provider value={authContext}>
      <EventContext.Provider value={eventContext}>
        <BrowserRouter>
          <CSSReset />
          <Navigation />
          <Switch>
            <Route path={'/'} component={Home} exact />
            <Route path={'/entreprise'} component={Entreprise} exact />
            <Route path={'/evenements'} exact>
              <EventsList />
            </Route>
            <Route path={'/Shotgun'} component={Shotgun} exact />
            <Route path={'/Logout'} component={Logout} exact />
            <Route path={'/google20b786008ca63c97.html'} exact>
              google-site-verification: google20b786008ca63c97.html
            </Route>
            <Route path={'/create-event/:id?'}>
              <CreateEvent />
            </Route>
            <Route path={'/superAdmin'} exact>
              <SuperAdmin />
            </Route>
            <Route path={'*'}>
              <Redirect to="/" />
            </Route>
          </Switch>
          <Separator />
          <br />
          <Footer />
          <Toaster />
        </BrowserRouter>
      </EventContext.Provider>
    </AuthContext.Provider>
  );
}

const CSSReset = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    background-color: #f2f2f2;
    margin-right: auto;
  }`;

const Separator = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export default App;
