import logo from '../image/logo.png';
import React from 'react';
import styled from 'styled-components';

export default function Footer() {
  return (
    <StyledFooter.Wrapper>
      <StyledFooter.Logo src={logo} />
      <StyledFooter.SocialInfo>
        <StyledFooter.Title>Suivez-nous sur les réseaux !</StyledFooter.Title>
        <div>
          <StyledFooter.SocialMedia
            imgUrl={process.env.PUBLIC_URL + '/facebook.png'}
            href="https://www.facebook.com/MagnumOenoCS/"
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {' '}
          </StyledFooter.SocialMedia>
          <StyledFooter.SocialMedia
            imgUrl={process.env.PUBLIC_URL + '/linkedin.png'}
            href="https://www.linkedin.com/company/magnum-centralesup%C3%A9lec/"
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {' '}
          </StyledFooter.SocialMedia>
          <StyledFooter.SocialMedia
            imgUrl={process.env.PUBLIC_URL + '/insta.png'}
            href="https://www.instagram.com/magnum.centralesupelec/"
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {' '}
          </StyledFooter.SocialMedia>
        </div>
      </StyledFooter.SocialInfo>
      <StyledFooter.Invisible />
    </StyledFooter.Wrapper>
  );
}

type FooterProps = {
  imgUrl: string;
};

const StyledFooter = {
  Wrapper: styled.nav`
    display: flex;
    justify-content: space-between;
    max-width: 1140px;
    width: 100%;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;

    @media only screen and (max-width: 40em) {
      flex-direction: column;
    }
  `,

  Logo: styled.img`
    width: 200px;
    pointer-events: none;
    object-fit: cover;

    @media only screen and (max-width: 40em) {
      margin-right: 50px;
    }
  `,

  SocialMedia: styled.a<FooterProps>`
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(${(props) => props.imgUrl});
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    background-size: 100% auto;
  `,

  Title: styled.h4`
    font-family: 'Playfair Display', serif;
    line-height: 1.3;
    font-weight: 400;
    font-size: 22px;
    color: #252525;
    margin-bottom: 30px;
    margin-top: 0;
    display: block;
    text-transform: capitalize;
    text-align: left;

    @media only screen and (max-width: 40em) {
      text-align: center;
    }
  `,

  SocialInfo: styled.div`
    display: flex;
    padding-right: 15px;
    padding-left: 15px;
    flex-direction: column;
    align-items: flex-start;
    max-width: 280px;

    @media only screen and (max-width: 40em) {
      margin-top: 20px;
      align-items: center;
    }
  `,

  Invisible: styled.div`
    width: 200px;
    height: 100px;
    background: none;

    @media only screen and (max-width: 40em) {
      height: 1px;
    }
  `,
};
