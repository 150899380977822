import { useState, useEffect } from 'react';

export default function useMedia<T>(queries: Array<string>, values: Array<T>, defaultValue: T) {
  const match = () =>
    values[queries.findIndex((q: string) => matchMedia(q).matches)] || defaultValue;
  const [value, set] = useState(match);
  useEffect(() => {
    const handler = () => set(match);
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  });
  return value;
}
