import { RefObject, useEffect } from 'react';

export default function useClickHandler(action: () => void, refs: Array<RefObject<any>>) {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (refs.map((ref) => ref.current && ref.current.contains(e.target as Node)).includes(true)) {
        // click inside component
        return;
      }
      action();
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
}
