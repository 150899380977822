import produce, { Draft } from 'immer';
import { InMemoryAuth, NoAuth } from '../types/inMemoryAuth';

export interface AuthState {
  auth: InMemoryAuth;
}

export const initialAuthState: AuthState = {
  auth: NoAuth
};

export enum AuthActionType {
  AUTH_UPDATED,
}

export type AuthUpdatedAction = {
  type: AuthActionType.AUTH_UPDATED;
  params: InMemoryAuth;
};

export type AuthAction = AuthUpdatedAction;

export const authReducer = (state: AuthState, action: AuthAction): AuthState =>
  produce(state, (draft: Draft<AuthState>) => {
    switch (action.type) {
      case AuthActionType.AUTH_UPDATED:
        draft.auth = action.params;
        break;
    }
  });
