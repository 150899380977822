export class MagnumEvent {
  id: string;
  title: string;
  body: string;
  type: string;
  image: string;
  date: string;
  pics: string;

  constructor(
    id: string,
    title: string,
    body: string,
    type: string,
    image: string,
    date: string,
    pics: string,
  ) {
    this.id = id;
    this.title = title;
    this.body = body;
    this.type = type;
    this.image = image;
    this.date = date;
    this.pics = pics;
  }
}

export const NoEvent = new MagnumEvent('NO_EVENT', '', '', '', '', '', '');
