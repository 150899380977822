import styled from 'styled-components';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobile } from 'react-device-detect';

function Entreprise() {
  gsap.registerPlugin(ScrollTrigger);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  function useCreateRef<T>(): [MutableRefObject<T[]>, (el: T) => void] {
    const ref = useRef<T[]>([]);
    ref.current = [];
    const addToRef = (el: T) => {
      if (el && !ref.current.includes(el)) {
        ref.current.push(el);
      }
    };

    return [ref, addToRef];
  }

  const [atoutTitleRefs, addToAtoutTitleRefs] = useCreateRef<HTMLDivElement>();
  const [atoutTexteRefs, addToAtoutTexteRefs] = useCreateRef<HTMLDivElement>();
  const [presentationRefs, addToPresentationRefs] = useCreateRef<HTMLDivElement>();
  const [vignetteRefs, addToVignettesRefs] = useCreateRef<HTMLDivElement>();

  useEffect(() => {
    atoutTitleRefs.current.forEach((el, i) => {
      gsap.fromTo(
        el,
        {
          x: isMobile ? -50 : -50 + (window.innerWidth * i) / 5,
          opacity: 0,
        },
        {
          x: isMobile ? 0 : (window.innerWidth * i) / 5,
          opacity: 1,
          scrollTrigger: {
            trigger: el,
            start: 'top 70%',
            end: 'top 70%',
            toggleActions: 'restart none reverse reset',
            scrub: 1,
          },
        },
      );
    });
  }, [atoutTitleRefs]);

  useEffect(() => {
    atoutTexteRefs.current.forEach((el, i) => {
      gsap.fromTo(
        el,
        {
          x: isMobile ? 0 : (window.innerWidth * i) / 5,
          opacity: 0,
        },
        {
          x: isMobile ? 0 : 50 + (window.innerWidth * i) / 5,
          opacity: 1,
          scrollTrigger: {
            trigger: el,
            start: 'top 70%',
            end: 'top 70%',
            toggleActions: 'restart none reverse reset',
            scrub: 1,
          },
        },
      );
    });
  }, [atoutTexteRefs]);

  useEffect(() => {
    presentationRefs.current.forEach((el) => {
      gsap.fromTo(
        el,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: el,
            start: 'top 70%',
            end: 'top 70%',
            toggleActions: 'restart none reverse reset',
            scrub: 1,
          },
        },
      );
    });
  }, [presentationRefs]);

  useEffect(() => {
    vignetteRefs.current.forEach((el) => {
      gsap.fromTo(
        el,
        {
          x: -window.innerWidth / 12,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: el,
            start: 'top 70%',
            end: 'top 70%',
            toggleActions: 'restart none reverse reset',
            scrub: 1,
          },
        },
      );
    });
  }, [vignetteRefs]);

  return (
    <>
      <Intro.Wrapper>
        <Intro.Image src={"/ent/entreprise_image.jpg"}/>
        <Intro.TitleWrapper>
          <Intro.Title>Rencontre Œnologique</Intro.Title>
          <Intro.Subtitle>
            Partagez un moment privilégié avec les étudiants de CentraleSupélec
          </Intro.Subtitle>
          <Intro.Text>
            Nous souhaitons donner une opportunité unique aux entreprises de rencontrer des
            élèves-ingénieurs de CentraleSupélec de façon chaleureuse et informelle. Cet événement
            permet aux entreprises de recruter les profils les plus adaptés à leurs attentes autour
            de dégustations œnologiques très enrichissantes.
          </Intro.Text>
        </Intro.TitleWrapper>
      </Intro.Wrapper>
      <StyledText.Wrapper>
        <StyledText.Title ref={addToPresentationRefs}>
          Les atouts d'un tel évènement
        </StyledText.Title>
        <VSpace2> </VSpace2>
      </StyledText.Wrapper>
      <StyledText.Wrapper ref={addToAtoutTitleRefs}>
        <SmallTitle>
          <Vignette src="/ent/vignette_ticket.png" alt="vignette ticket" />
          <Align>Une expertise</Align>
        </SmallTitle>
      </StyledText.Wrapper>
      <StyledText.Wrapper ref={addToAtoutTexteRefs}>
        <SmallText>Notre professionnalisme est reconnu par les professionnels du vin.</SmallText>
      </StyledText.Wrapper>
      <StyledText.Wrapper ref={addToAtoutTitleRefs}>
        <SmallTitle>
          <Vignette src="/ent/vignette_montre.png" alt="vignette ticket" />
          <Align>Capacité d'adaptation</Align>
        </SmallTitle>
      </StyledText.Wrapper>
      <StyledText.Wrapper ref={addToAtoutTexteRefs}>
        <SmallText>Organisation tenant compte des mesures sanitaires.</SmallText>
      </StyledText.Wrapper>
      <StyledText.Wrapper ref={addToAtoutTitleRefs}>
        <SmallTitle>
          <Vignette src="/ent/vignette_main.png" alt="vignette ticket" />
          <Align>Synergie entre élèves et professionnels</Align>
        </SmallTitle>
      </StyledText.Wrapper>
      <StyledText.Wrapper ref={addToAtoutTexteRefs}>
        <SmallText>
          Les élèves seront sélectionnés sur leur motivation vis-à-vis de l'entreprise.
        </SmallText>
      </StyledText.Wrapper>
      <StyledText.Wrapper ref={addToAtoutTitleRefs}>
        <SmallTitle>
          <Vignette src="/ent/vignette_bouteille.png" alt="vignette ticket" />
          <Align>Format idéal</Align>
        </SmallTitle>
      </StyledText.Wrapper>
      <StyledText.Wrapper ref={addToAtoutTexteRefs}>
        <SmallText>
          L'œnologie est le support de la discussion. Le contexte est convivial.
        </SmallText>
      </StyledText.Wrapper>
      <StyledText.Wrapper>
        <div ref={addToPresentationRefs}>
          <StyledText.Title>L'évènement</StyledText.Title>
          <StyledText.Wrapper>
            <SmallText>
              <Vignette src="/ent/etudiant.png" alt="etudiant" />
              <Align>25 à 50 étudiants : 4 à 6 par table</Align>
            </SmallText>
            <SmallText>
              <Vignette src="/ent/entrepreneur.png" alt="entrepreneur" />
              <Align>1 à 2 collaborateurs par table</Align>
            </SmallText>
          </StyledText.Wrapper>
        </div>
        <VSpace> </VSpace>
        <StyledText.Wrapper id="pres-ent" ref={addToVignettesRefs}>
          <SmallTitle>
            <Vignette src="/ent/presentation.png" alt="presentation" />
            <Align>Présentation de votre entreprise (20 minutes)</Align>
          </SmallTitle>
        </StyledText.Wrapper>
        <StyledText.Wrapper id="degust" ref={addToVignettesRefs}>
          <SmallTitle>
            <Vignette src="/ent/cheers.png" alt="cheers" />
            <Align>Présentation des vins et partages d'expériences par table (1h30)</Align>
          </SmallTitle>
        </StyledText.Wrapper>
        <StyledText.Wrapper id="cocktail" ref={addToVignettesRefs}>
          <SmallTitle>
            <Vignette src="/ent/cocktail.png" alt="cocktail" />
            <Align>Echanges libres autour d'un cocktail (1h)</Align>
          </SmallTitle>
        </StyledText.Wrapper>
        <VSpace> </VSpace>
      </StyledText.Wrapper>
      <div ref={addToPresentationRefs}>
        <StyledText.Wrapper>
          <StyledText.Title>Notre offre</StyledText.Title>
        </StyledText.Wrapper>
        <VSpace2> </VSpace2>
      </div>
      <div style={{ height: '300vh' }}>
        <OfferPresentation.Wrapper>
          <OfferPresentation.Image image={'/ent/pack-authentique.jpg'} />
          <OfferPresentation.Caption>
            <OfferPresentation.Title>{'Pack Authentique'}</OfferPresentation.Title>
            <OfferPresentation.Body>
              {"L'intervenant est un membre de l'association formé. \n\n" +
                'Vins "authentiques" la bouteille, ardoises de la fromagerie d\'Orsay.\n\n' +
                "Buffet gourmand de la fromagerie d'Orsay, crémant d'Alsace.\n\n" +
                'Campus informé par mail et Facebook, photographe présent pour la soirée.\n\n'}
            </OfferPresentation.Body>
          </OfferPresentation.Caption>
        </OfferPresentation.Wrapper>
        <OfferPresentation.Wrapper>
          <OfferPresentation.Image image={'/ent/pack-premium.jpg'} />
          <OfferPresentation.Caption>
            <OfferPresentation.Title>{'Pack Premium'}</OfferPresentation.Title>
            <OfferPresentation.Body>
              {"L'intervenant est un œnologue professionel.\n\n" +
                'Vins "premiums", amuse-bouches "premium" de la Maison Lacaille.\n\n' +
                "Buffet gourmand de la fromagerie d'Orsay, crémant d'Alsace.\n\n" +
                'Campus informé par mail et Facebook, photographe présent pour la soirée, partage de vos offres.\n\n'}
            </OfferPresentation.Body>
          </OfferPresentation.Caption>
        </OfferPresentation.Wrapper>
        <OfferPresentation.Wrapper style={{ position: 'relative' }}>
          <OfferPresentation.Image image={'/ent/pack-prestige.jpg'} />
          <OfferPresentation.Caption>
            <OfferPresentation.Title>{'Pack Prestige'}</OfferPresentation.Title>
            <OfferPresentation.Body>
              {"L'intervenant est un œnologue professionel.\n\n" +
                'Vins d\'exeptions la bouteille, amuse-bouches "prestige" de la Maison Lacaille.\n\n' +
                'Cocktail "prestige" de la Maison Lacaille, animation culinaire, champagne Signature Blanc de Blanc de la Maison Gonet.\n\n' +
                "Campus informé par mail et Facebook, photographe présent pour la soirée, partage de vos offres, affiches et flyers de l'évènement.\n\n"}
            </OfferPresentation.Body>
          </OfferPresentation.Caption>
        </OfferPresentation.Wrapper>
      </div>

      <StyledText.Wrapper ref={addToPresentationRefs}>
        <StyledText.Title>Contacts</StyledText.Title>
        <StyledText.Text>
          Si les soirées entreprise vous intéressent et que vous désirez avoir plus d'informations,
          n'hésitez pas à nous contacter !
        </StyledText.Text>
        <StyledText.Col>
          <StyledText.KeepTogether>
            <StyledText.TextCentered>
              <img
                src="/ent/antonin.jpg"
                width="200px"
                style={{ alignContent: 'centered' }}
                alt="Antonin Allemand"
              />
              <br />
              Valentin Marchesini
              <br />
              Président
              <br />
              <a href="mailto:valentin.marchesini@student-cs.fr">valentin.marchesini[at]student-cs.fr</a>
            </StyledText.TextCentered>
          </StyledText.KeepTogether>
        </StyledText.Col>
      </StyledText.Wrapper>
    </>
  );
}

type ImageProperties = {
  image: string;
};

const StyledText = {
  Wrapper: styled.div`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    background-color: #f2f2f2;
    padding: 0 1em;

    @media only screen and (max-width: 50em) {
      width: 100%;
      padding: 0;
      margin-left: 0;
      align-items: center;
      justify-content: center;
    }
  `,

  Title: styled.h2`
    font-family: 'Playfair Display', serif;
    text-align: left;
    max-width: 100%;
    font-size: 50px;
    padding: 30px 0 30px 20px;

    @media only screen and (max-width: 50em) {
      font-size: 35px;
      width: 100%;
    }
  `,

  Text: styled.div`
    padding: 10px 30px;
    text-align: justify;
    line-height: 2;
    font-weight: 400;
    font-size: 24px;
    width: 100%;

    @media only screen and (max-width: 50em) {
      font-size: 15px;
    }
  `,

  TextCentered: styled.div`
    text-align: center;
    line-height: 1.5;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    padding: 1rem 3rem;
    @media only screen and (max-width: 50em) {
      font-size: 20px;
    }
  `,

  Col: styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 50em) {
      width: 95vw;
      column-count: 1;
    }
  `,

  KeepTogether: styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1000;
    width: 300px;
    text-align: left;
    align-items: center;
  `,

  Type: styled.p`
    text-transform: uppercase;
    font-size: 14px;
    max-width: 300px;
    font-weight: 300;
    margin: 10px auto 0 auto;
    padding: 0 20px 0.75rem 20px;
    letter-spacing: 0.25rem;
    color: #999999;
    border-bottom: 1px solid #999999;
  `,

  vignette: styled.div<ImageProperties>`
    background-position: center;
    background-size: 100% auto;
    background-image: url(${(props) => process.env.PUBLIC_URL + props.image});
    width: 500px;
  `,
};

const Intro = {
  Wrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 700px;
    justify-content: center;

    &:before {
      background-image: url(${process.env.PUBLIC_URL + '/ent/entreprise_image.jpg'});
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 2;
      filter: blur(4px) brightness(70%);
    }
  `,

  Text: styled.div`
    padding: 20px 40px;
    width: 40%;
    text-align: justify;
    line-height: 2;
    font-weight: 400;
    font-size: 18px;
    background-color: white;
    opacity: 90%;
    box-shadow: 0 2px 18px 0 rgb(0 0 0 / 30%);
    margin-top: 10vh;
    min-height: 250px;
    display: flex;
    align-items: center;
    
    @media only screen and (max-width: 50em) {
      font-size: 15px;
      width: 80%;
    }
  `,

  Title: styled.h2`
    font-family: 'Playfair Display', serif;
    text-align: center;
    max-width: 100%;
    font-size: 60px;
    padding: 10px 0 10px 10px;
    color: white;

    @media only screen and (max-width: 50em) {
      font-size: 45px;
      width: 100%;
    }
  `,

  Subtitle: styled.h2`
    font-family: 'Playfair Display', serif;
    text-align: center;
    max-width: 100%;
    font-size: 30px;
    padding: 10px 0 10px 10px;
    color: white;

    @media only screen and (max-width: 50em) {
      font-size: 20px;
      width: 80%;
    }
  `,

  Image: styled.img`
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
    vertical-align: center;
    height: 80%;
    min-height: 600px;
    filter: blur(4px) brightness(70%);

    @media only screen and (max-width: 50em) {
      width: 100%;
      background-size: cover;
    }
  `,

  TitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 30vh;
    align-items: center;
    margin-bottom: 20px;
  `,
};

const OfferPresentation = {
  Wrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    background-color: #f2f2f2;
    z-index: 1;
    position: sticky;
    top: 0;
  `,

  Col: styled.div`
    display: grid;
    @media only screen and (max-width: 50em) {
      width: 90%;
    }
  `,

  Image: styled.img<ImageProperties>`
    background-position: center;
    filter: blur(4px) brightness(70%);
    background-size: cover;
    background-image: url(${(props) => process.env.PUBLIC_URL + props.image});
    width: 100%;
    height: 100vh;
  `,

  Body: styled.div`
    text-align: left;
    width: 80%;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    @media only screen and (max-width: 50em) {
      font-size: 18px;
    }
  `,

  Caption: styled.div`
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    color: white;
    width: 70%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 50em) {
      width: 90%;
    }
  `,

  Title: styled.p`
    text-transform: uppercase;
    font-size: 40px;
    max-width: 1000px;
    font-weight: 300;
    margin: 30px auto 20px auto;
    padding: 0 20px 0.75rem 20px;
    letter-spacing: 0.25rem;
    border-bottom: 2px solid #999999;

    @media only screen and (max-width: 50em) {
      font-size: 30px;
      margin: 0;
      border-bottom: 1px solid #999999;
    }
  `,
};

const SmallTitle = styled.h4`
  align-items: center;
  display: flex;
  float: left;
  font-family: 'Playfair Display', serif;
  text-align: left;
  max-width: 90%;
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 20px;
  @media only screen and (max-width: 50em) {
    width: 90vw;
    font-size: 25px;
    padding: 0;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 7px;
  }
`;

const SmallText = styled.div`
  align-items: center;
  padding: 0 0 0.5em 1.5em;
  display: flex;
  text-align: left;
  line-height: 2;
  width: ${window.innerWidth / 3}px;
  font-weight: 400;
  font-size: 25px;

  @media only screen and (max-width: 50em) {
    width: 95vw;
    font-size: 20px;
    text-align: center;
    padding: 0 10px;
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const Align = styled.div`
  padding: 0 0.5em;
`;

const VSpace = styled.div`
  padding-bottom: 80px;
  @media only screen and (max-width: 50em) {
    padding-bottom: 4em;
  }
`;

const Vignette = styled.img`
  width: 70px;
  height: 70px;
`;

const VSpace2 = styled.div`
  padding-bottom: 3em;
  @media only screen and (max-width: 50em) {
    padding-bottom: 1em;
  }
`;

export default Entreprise;
