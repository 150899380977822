import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import DatePicker from 'react-date-picker';
import { useParams } from 'react-router-dom';
import { doGet, doPost, doPut } from '../Rest/Fetcher';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { CreateEventParams } from '../types/dataTypes';
import { AuthContext } from '../appContext';

interface FormInputs {
  title: string;
  date: Date;
  type: string;
  body: string;
  pics: string;
  images: (File & { preview: string })[];
  image: boolean;
}

export default function CreateEvent() {
  const { id } = useParams<CreateEventParams>();
  const { authState } = useContext(AuthContext);
  const { register, handleSubmit, control, setValue, watch, getValues, setError } =
    useForm<FormInputs>();
  const watchImage = watch('image', false);
  const watchImages = watch('images', []);
  const watchAll = watch();

  useEffect(() => {
    setError('title', {
      types: {
        required: 'This is required',
      },
    });
  }, [setError]);

  useEffect(() => {
    if (id !== undefined)
      (async () => {
        try {
          const fetchedEvent: FormInputs = await doGet('/api/events/' + id);
          fetchedEvent.date = new Date(fetchedEvent.date);
          let key: keyof typeof fetchedEvent;
          for (key in fetchedEvent) {
            setValue(key, fetchedEvent[key]);
          }
        } catch (error) {
          window.location.assign('/create-event');
        }
      })();
  }, [id, setValue]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpg, image/png, image/jpeg',
    onDrop: (acceptedFiles) => {
      setValue(
        'images',
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const postEvent = async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (key !== 'image' && key !== 'images') formData.append(key, data[key]);
    }
    formData.append('image', getValues('images')[0]);

    try {
      id !== undefined
        ? await doPut(authState.auth.token, '/api/events/' + id, formData)
        : await doPost(authState.auth.token, '/api/events/', formData);
      toast.success('Event posted successfully');
      window.location.assign('/evenements');
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <Edit.Wrapper>
      <Input.FormContainer onSubmit={handleSubmit(postEvent)}>
        <Edit.Title>Editer l'Evenement</Edit.Title>
        <Input.Wrapper>
          <Input.Name>Titre de l'évenement :</Input.Name>
          <Input.Input
            type="text"
            placeholder="Soirée avec le Château Haut-Bailly"
            {...register('title', { required: true })}
            isNotFilled={watchAll.title === ''}
          />
        </Input.Wrapper>
        <Input.SmallWrapper>
          <Input.Name>Date :</Input.Name>
          <Controller
            control={control}
            name="date"
            rules={{ required: true }}
            defaultValue={undefined}
            render={({ field: { onChange, value } }) => (
              <Input.Date
                onChange={onChange}
                isNotFilled={watchAll.date === null || watchAll.date === undefined}
                calendarAriaLabel={'date'}
                value={value}
                locale={'zu'}
              />
            )}
          />
        </Input.SmallWrapper>
        <Input.SmallWrapper>
          <Input.Name>Type d'évenement :</Input.Name>
          <Input.Input
            type="text"
            placeholder="Dégustation"
            isNotFilled={watchAll.type === ''}
            {...register('type', { required: true })}
          />
        </Input.SmallWrapper>
        <Input.Wrapper>
          <Input.Name>Image :</Input.Name>
          <Controller
            control={control}
            name={'images'}
            defaultValue={[]}
            render={({ field: { onChange, onBlur } }) => (
              <>
                <Input.Container
                  {...getRootProps({ isDragAccept, isDragReject })}
                  onDrop={onChange}
                  isNotFilled={watchImages[0] === undefined && !watchImage}
                >
                  <input {...getInputProps()} onBlur={onBlur} />
                  {watchImages[0] !== undefined ? (
                    <Input.Image src={watchImages[0].preview} />
                  ) : (
                    <>
                      {watchImage ? (
                        <Input.Image src={'/api/images/' + watchImage} />
                      ) : (
                        <div style={{ padding: '20px' }}>
                          Drag 'n' drop some files here, or click to select files
                        </div>
                      )}
                    </>
                  )}
                </Input.Container>
              </>
            )}
          />
        </Input.Wrapper>
        <Input.Wrapper>
          <Input.Name>Déscription de l'évenement :</Input.Name>
          <Input.Body
            placeholder="Nous avons eu le plaisir de recevoir le château Haut-Bailly avec qui nous avons partagé un bon moment de convivialité. Nous avons profité d'une verticale de leur grand vin et de la découverte de leur second vin. Tout ceci dans la joie et la bonne humeur!"
            isNotFilled={watchAll.body === ''}
            {...register('body', { required: true })}
          />
        </Input.Wrapper>
        <Input.Wrapper>
          <Input.Name>Lien vers la galerie Pics :</Input.Name>
          <Input.Input type="text" placeholder="galerie.pics" {...register('pics')} />
        </Input.Wrapper>
        <Input.Submit
          type="submit"
          disabled={
            !(
              watchAll.title !== '' &&
              watchAll.body !== '' &&
              watchAll.type !== '' &&
              (watchImages[0] || watchImage) &&
              watchAll.date
            )
          }
        />
      </Input.FormContainer>
    </Edit.Wrapper>
  );
}

type GetColorProps = {
  isDragAccept: boolean;
  isDragReject: boolean;
  isNotFilled: boolean;
};

const getColor = (props: GetColorProps) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject || props.isNotFilled) {
    return '#ff1744';
  }
  return 'gray';
};

type Inputprops = {
  isNotFilled?: boolean;
};

const Input = {
  FormContainer: styled.form`
    display: flex;
    margin-top: 80px;
    width: 800px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 2em;
    background: white;
    border-radius: 0.4em;
  `,

  Input: styled.input<Inputprops>`
    background-color: #fafafa;
    border-style: solid;
    border-width: thin;
    border-radius: 0.2em;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border-color: ${(props) => (props.isNotFilled ? '#ff1744' : 'grey')};
  `,

  Body: styled.textarea<Inputprops>`
    width: 100%;
    margin: 10px 0;
    height: 400px;
    padding: 10px;
    background-color: #fafafa;
    border-style: solid;
    border-width: thin;
    border-color: ${(props) => (props.isNotFilled ? '#ff1744' : 'grey')};
    border-radius: 0.2em;
  `,

  Container: styled.div<GetColorProps>`
    cursor: pointer;
    flex: 1;
    display: flex;
    min-width: 400px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    border-width: 2px;
    border-radius: 0.2em;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin: 10px 0;
  `,

  Image: styled.img`
    max-height: 600px;
    width: 100%;
    object-fit: contain;
  `,

  Date: styled(DatePicker)<Inputprops>`
    height: 43px;
    margin: 10px 0;
    justify-content: center;
    background-color: #fafafa;

    .react-date-picker__wrapper {
      border-color: ${(props) => (props.isNotFilled ? '#ff1744' : 'grey')};
      padding: 10px;
    }
  `,

  Wrapper: styled.div`
    width: 100%;
  `,

  SmallWrapper: styled.div`
    width: 30%;
  `,

  Name: styled.div`
    font-family: 'Playfair Display', serif;
    max-width: 70%;
    font-weight: 600;
    font-size: 16px;
  `,

  Submit: styled.input`
    background: rgb(156, 143, 127);
    width: 150px;
    padding: 10px;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 1.1em;
    text-align: center;
    border-radius: 0.4em;
    border: thin solid gray;
    margin-left: calc(325px - 2em);

    &:hover {
      background: rgb(93, 73, 59);
    }

    &:disabled {
      background: rgba(156, 143, 127, 0.7);
      cursor: default;
    }
  `,
};

const Edit = {
  Wrapper: styled.div`
    width: 100%;
    justify-content: center;
    display: flex;
  `,

  Title: styled.div`
    font-family: 'Playfair Display', serif;
    line-height: 1.3;
    font-weight: 300;
    font-size: 25px;
    color: #252525;
    margin-bottom: 30px;
    margin-top: 0;
    display: block;
    text-transform: capitalize;
    text-align: center;
    width: 100%;
  `,
};
