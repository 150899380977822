import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import logo from '../image/logo.png';
import useClickHandler from '../hooks/useClickHandler';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../appContext';

export default function Navigation() {
  let url = document.location.href.split('/')[3];

  if (url === '') {
    url = 'accueil';
  }

  let localhost =
    document.location.href.split('/')[2] === 'localhost:3000' ? 'http://localhost:8000' : '';

  const [activeTab, setActiveTab] = useState(url);
  const { authState } = useContext(AuthContext);

  //Button Listener pour fermer le menu si l'utilisateur clique en dehors du menu
  const [openDrawer, toggleDrawer] = useState(false);
  const [logoutDropdown, setLogoutDropdown] = useState(false);
  const drawerRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const logoutRef = useRef<HTMLLIElement>(null);

  const history = useHistory();

  const handleClick = (path: string) => {
    history.push(path);
    window.scrollTo({ top: 0 });
  };

  const handleLogin = () => {
    localStorage.setItem('isLogged', 'true');
    window.location.assign(localhost + `/api/auth/login/` + url);
  };

  useClickHandler(() => setLogoutDropdown(false), [logoutRef]);
  useClickHandler(() => toggleDrawer(false), [buttonRef, drawerRef]);

  return (
    <Navbar.Wrapper>
      <Navbar.Logo src={logo} />
      <HamburgerButton.Wrapper
        ref={buttonRef}
        onClick={() => {
          toggleDrawer(!openDrawer);
        }}
      >
        <HamburgerButton.Lines />
      </HamburgerButton.Wrapper>

      <Navbar.Items ref={drawerRef} openDrawer={openDrawer}>
        <Navbar.Item isActive={'accueil' === activeTab} onClick={() => setActiveTab('accueil')}>
          <Navbar.NavItem id={'accueil'} onClick={() => handleClick('/')}>
            Accueil
          </Navbar.NavItem>
        </Navbar.Item>
        <Navbar.Item
          isActive={'entreprise' === activeTab}
          onClick={() => setActiveTab('entreprise')}
        >
          <Navbar.NavItem id={'entreprise'} onClick={() => handleClick('/entreprise')}>
            Entreprise
          </Navbar.NavItem>
        </Navbar.Item>
        <Navbar.Item
          key={'evenements'}
          isActive={'evenements' === activeTab}
          onClick={() => setActiveTab('evenements')}
        >
          <Navbar.NavItem id={'evenements'} onClick={() => handleClick('/evenements')}>
            Evenements
          </Navbar.NavItem>
        </Navbar.Item>
        {authState.auth.admin === 'SUPERADMIN' ? (
          <Navbar.Item
            key={'superAdmin'}
            isActive={'superAdmin' === activeTab}
            onClick={() => setActiveTab('superAdmin')}
          >
            <Navbar.NavItem id={'superadmin'} onClick={() => handleClick('/superAdmin')}>
              Gestion Admins
            </Navbar.NavItem>
          </Navbar.Item>
        ) : (
          <></>
        )}

        {authState.auth.token !== '' ? (
          <Navbar.LogoutWrapper ref={logoutRef}>
            <Navbar.LogoutItem id={'Login'} onClick={() => setLogoutDropdown(!logoutDropdown)}>
              {authState.auth.fullName}
            </Navbar.LogoutItem>
            {logoutDropdown ? (
              <Navbar.DropDown>
                <Navbar.DropDownContent href={localhost + '/api/auth/logout'}>
                  Se Déconnecter
                </Navbar.DropDownContent>
              </Navbar.DropDown>
            ) : (
              <></>
            )}
          </Navbar.LogoutWrapper>
        ) : (
          <Navbar.LogoutWrapper ref={logoutRef}>
            <Navbar.LoginItem id={'Login'} onClick={handleLogin}>
              Se Connecter
            </Navbar.LoginItem>
          </Navbar.LogoutWrapper>
        )}
      </Navbar.Items>
    </Navbar.Wrapper>
  );
}

type NavbarProps = {
  isActive?: boolean;
  openDrawer?: boolean;
};

const Navbar = {
  Wrapper: styled.nav`
    display: flex;
    justify-content: space-between;
    width: 100vw;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
    margin-right: 0;
    position: fixed;
    top: 0;
    background: rgba(242, 242, 242, 0.7);
    z-index: 5;

    @media only screen and (max-width: 50em) {
      position: absolute;
      width: 100%;
    }
  `,

  Logo: styled.img`
    width: 150px;
    pointer-events: none;
    object-fit: cover;
  `,

  NavItem: styled.div`
    font-size: 18px;
    text-transform: capitalize;
    color: inherit;
    display: inline-block;
    height: 35px;
    line-height: 34px;
    text-decoration: none;
    transition-duration: 500ms;
    font-weight: 400;
    cursor: pointer;
    list-style: none;

    &:hover {
      color: #9c8f7f;
      text-decoration: none;
    }
  `,

  Items: styled.ul<NavbarProps>`
    box-sizing: border-box;
    display: flex;
    list-style: none;
    z-index: 2;
    margin-right: 3em;

    @media only screen and (max-width: 50em) {
      position: fixed;
      right: 0;
      top: 0;
      margin-right: 0;

      height: 100%;

      flex-direction: column;

      background-color: white;
      padding: 1rem 2rem;

      transform: ${({ openDrawer }) => (openDrawer ? `translateX(0)` : `translateX(100%)`)};
    }
  `,
  Item: styled.li<NavbarProps>`
    margin: 0 1rem;
    color: ${(props) => (props.isActive ? '#9C8F7F' : '#252525')};
    cursor: pointer;
    border-bottom: ${(props) => (props.isActive ? '2px solid #9C8F7F' : 'none')};

    @media only screen and (max-width: 50em) {
      padding: 1rem 0;
      border-bottom: none
    };
  }
  `,

  LogoutItem: styled.a`
    position: relative;
    font-size: 18px;
    text-transform: capitalize;
    color: inherit;
    display: inline-block;
    height: 35px;
    line-height: 34px;
    text-decoration: none;
    transition-duration: 500ms;
    font-weight: 400;
    cursor: pointer;
    list-style: none;
    padding: 0 10px;
    margin: 0 1rem;

    &:hover {
      color: #9c8f7f;
      text-decoration: none;
    }

    &:after {
      border-top: 7px solid;
      border-left: 7px solid rgba(0, 0, 0, 0);
      border-right: 7px solid rgba(0, 0, 0, 0);
      content: '';
      display: inline-block;
      left: 98%;
      position: absolute;
      top: 13px;
    }

    @media only screen and (max-width: 50em) {
      margin-right: 20px;
      padding: 1rem 0;

      &:after {
        top: calc(1rem + 13px);
        left: 104%;
      }
    }
  `,

  LoginItem: styled.div`
    font-size: 18px;
    text-transform: capitalize;
    color: inherit;
    display: inline-block;
    height: 35px;
    line-height: 34px;
    text-decoration: none;
    transition-duration: 500ms;
    font-weight: 400;
    cursor: pointer;
    list-style: none;
    margin: 0 1rem;

    &:hover {
      color: #9c8f7f;
      text-decoration: none;
    }

    @media only screen and (max-width: 50em) {
      margin: 1rem;
    } ;
  `,

  LogoutWrapper: styled.li``,

  DropDown: styled.div`
    background: white;
    position: absolute;
    align-items: center;
    padding: 0.4em;
    right: 5em;
    top: 3.2em;
    z-index: 100;
    display: flex;
    justify-content: center;
    border-radius: 0.2em;
    flex-direction: column;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    @media only screen and (max-width: 50em) {
      top: 16em;
      right: 2em;
      align-items: flex-start;
      padding-left: 10px;
    }
  `,

  DropDownContent: styled.a`
    font-size: 16px;
    text-transform: capitalize;
    color: inherit;
    display: inline-block;
    height: auto;
    line-height: 34px;
    text-decoration: none;
    transition-duration: 500ms;
    font-weight: 400;
    cursor: pointer;
    list-style: none;

    &:hover {
      color: #9c8f7f;
      text-decoration: none;
    }
  `,
};

const HamburgerButton = {
  Wrapper: styled.div`
    height: 3rem;
    width: 3rem;
    position: fixed;
    top: 15px;
    right: 20px;
    font-size: 12px;
    display: none;
    z-index: 3;
    border: none;
    background: transparent;
    cursor: pointer;

    @media only screen and (max-width: 50em) {
      display: block;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 150%;
      width: 150%;
      top: -25%;
      left: -25%;
    }
  `,

  Lines: styled.div`
    top: 50%;
    margin-top: -0.125em;

    &,
    &:after,
    &:before {
      height: 2px;
      pointer-events: none;
      display: block;
      content: '';
      width: 100%;
      background-color: black;
      position: absolute;
    }

    &:after {
      /* Move bottom line below center line */
      top: -0.8rem;
    }

    &:before {
      /* Move top line on top of center line */
      top: 0.8rem;
    }
  `,
};
