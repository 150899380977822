import React, { useContext, useEffect, useState } from 'react';
import { Admin } from '../types/dataTypes';
import { doDelete, doGet, doPost, doPut } from '../Rest/Fetcher';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { AuthContext } from '../appContext';

interface AdminEventTarget {
  name: 'username' | 'role' | 'newUsername';
  value: any;
}

interface FormAdmin {
  username: string;
  role: string;
  newUsername: string;
}

export default function SuperAdmin() {
  const [isLoading, setIsLoading] = useState(false);
  const [admins, setAdmins] = useState<FormAdmin[]>([]);
  const { authState } = useContext(AuthContext);

  useEffect(
    () => {
      (async () => {
        if (authState.auth.token) {
          setIsLoading(true);
          try {
            const fetch: Admin[] = await doGet('/api/admin', authState.auth.token);
            const fetchedAdmins: FormAdmin[] = [...admins];
            fetch.forEach((admin) => {
              if (!admins.map((a) => a.username).includes(admin.username)) {
                fetchedAdmins.push({
                  ...admin,
                  newUsername: admin.username,
                });
              }
            });
            setAdmins(fetchedAdmins);
            setIsLoading(false);
          } catch (e) {
            setIsLoading(false);
            toast.error('Error retrieving admins');
          }
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authState.auth],
  );

  const handleAddAdmin = () => {
    setAdmins([...admins, { username: '', role: 'ADMIN', newUsername: '' }]);
  };

  const handleRemoveAdmin = (index: number) => {
    doDelete(authState.auth.token, '/api/admin/' + admins[index].username)
      .then(() => {
        const newAdmins: FormAdmin[] = [...admins];
        newAdmins.splice(index, 1);
        setAdmins(newAdmins);
      })
      .catch(() => {});
  };

  const handleChange = (event: any, index: number) => {
    const { name, value }: AdminEventTarget = event.target;
    const newAdmins: FormAdmin[] = [...admins];
    newAdmins[index][name] = value;
    setAdmins(newAdmins);
  };

  const handleCreate = (index: number) => {
    const admin = { username: admins[index].newUsername, role: admins[index].role };
    doPost(authState.auth.token, '/api/admin', JSON.stringify(admin))
      .then(() => {
        const newAdmins: FormAdmin[] = [...admins];
        newAdmins[index].username = admins[index].newUsername;
        setAdmins(newAdmins);
      })
      .catch((e) => console.log(e));
  };

  const handleUpdate = (index: number) => {
    doPut(authState.auth.token, '/api/admin', JSON.stringify(admins[index]))
      .then(() => {
        const newAdmins: FormAdmin[] = [...admins];
        newAdmins[index].username = admins[index].newUsername;
        setAdmins(newAdmins);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Wrapper>
      {isLoading ? (
        <div style={{ margin: '100px 0' }}>
          <Loader type="Oval" color="#9C8F7F" />
        </div>
      ) : (
        <div>
          {admins.map((admin, i) => {
            return (
              <div key={i}>
                <input
                  value={admin.newUsername}
                  name={'newUsername'}
                  onChange={(e) => handleChange(e, i)}
                />
                <select value={admin.role} name={'role'} onChange={(e) => handleChange(e, i)}>
                  <option value={'SUPERADMIN'}>SUPERADMIN</option>
                  <option value={'ADMIN'}>ADMIN</option>
                </select>
                <button onClick={() => handleRemoveAdmin(i)}>Delete</button>
                {admin.username === '' ? (
                  <button onClick={() => handleCreate(i)}>Create</button>
                ) : (
                  <button onClick={() => handleUpdate(i)}>Save</button>
                )}
              </div>
            );
          })}
        </div>
      )}
      <button onClick={handleAddAdmin}>Add</button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
`;
