async function doGet(path: string, accessToken?: string) {
  const headers = accessToken
    ? new Headers({ Authorization: 'Bearer ' + accessToken })
    : new Headers();
  const res = await fetch(path, { method: 'GET', headers });
  if (res.ok) {
    return await res.json();
  } else {
    return await throwError(res);
  }
}

async function doPut(accessToken: string, path: string, body: FormData | string) {
  const headers =
    typeof body === 'string'
      ? new Headers({
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        })
      : new Headers({
          Authorization: 'Bearer ' + accessToken,
        });
  const init = { method: 'PUT', headers, body: body };
  const res = await fetch(path, init);
  if (res.ok) {
    return await res.json();
  } else {
    return await throwError(res);
  }
}

async function doPost(accessToken: string, path: string, body: FormData | string) {
  const headers =
    typeof body === 'string'
      ? new Headers({
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        })
      : new Headers({
          Authorization: 'Bearer ' + accessToken,
        });
  const init = { method: 'POST', headers, body: body };
  const res = await fetch(path, init);
  if (res.ok) {
    return await res.json();
  } else {
    return await throwError(res);
  }
}

async function doDelete(accessToken: string, path: string) {
  const headers = new Headers({ Authorization: 'Bearer ' + accessToken });
  const init = { method: 'DELETE', headers };
  const res = await fetch(path, init);
  if (res.ok) {
    return await res.json();
  } else {
    return await throwError(res);
  }
}

async function throwError(res: Response) {
  switch (res.status) {
    case 404:
      throw Error("couldn't perform action");
    case 400:
      throw Error(`Bad request`);
    case 401:
      throw Error('Unauthorized, please relogin');
    default:
      throw Error(
        `A server error occurred. Details: [${res.status} ${res.statusText} ${await res.text()}]`,
      );
  }
}

export { doPut, doGet, doPost, doDelete };
