export class InMemoryAuth {
  admin: string;
  fullName: string;
  token: string;

  constructor(
    admin: string,
    fullName: string,
    token: string,
  ) {
    this.admin= admin;
    this.fullName= fullName;
    this.token= token;
  }
}

export const NoAuth = new InMemoryAuth('', '', '');